import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  /* background: #999; */
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
  overflow-x: scroll;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
  display: grid;
  /* border-collapse: collapse; */
  min-width: 100%;
  grid-template-columns:
    minmax(50px, 0.1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr);
  /* minmax(150px, 1fr)
    minmax(80px, 1fr); */
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  padding: 15px;
  /* word-wrap: break-word; */
  overflow: hidden;
  text-overflow: ;
  /* white-space: nowrap; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: green;
  text-align: center;
  font-weight: normal;
  font-size: 1.1rem;
  color: white;
  border-right: 1px solid #fff;
  /* &:first-child {
    grid-row: 1/3;
  } */
  /* &:nth-child(2) {
    /* grid-column-start: span 4; */
  /*border-bottom: 1px solid #fff;
    grid-column: 2/6;
    grid-row: 1/2;
  } */
  &:last-child {
    border-right: none;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  /* border: 1px solid #333; */
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #999;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  :first-child {
    border-left: 2px solid #999;
  }
`;

const data = [
  {
    num: '1',
    condition:
      'Обеспечение беспрепятственного доступа обучающихся с ограниченными возможностями здоровья, имеющих нарушения опорно-двигательного аппарата, в учебные помещения и другие помещения, а также их пребывания в указанных помещениях',
    info:
      'Кнопка вызова представителя ЧОУ ДПО «Профф», в проекте – строительство пандуса в 2021 году',
  },
  {
    num: '2',
    condition: 'Наличие специальных (адаптированных) образовательных программ',
    info: 'Имеется',
  },
  {
    num: '3',
    condition:
      'Наличие специальных учебников, учебных пособий и дидактических материалов',
    info:
      'Предоставление удаленного бесплатного доступа к электронной библиотеке ЧОУ ДПО «Профф»',
  },
  {
    num: '4',
    condition:
      'Наличие специальных технических средств обучения коллективного и индивидуального пользования',
    info:
      'Дистанционное обучение с использованием официального сайта ЧОУ ДПО «Профф», предоставление удаленного бесплатного доступа к электронной библиотеке ЧОУ ДПО «Профф»',
  },
  {
    num: '5',
    condition:
      'Возможность предоставления услуг ассистента (помощника), оказывающего обучающимся необходимую техническую помощь',
    info: 'Имеется',
  },
  {
    num: '6',
    condition:
      'Наличие доступа к информационным системам и информационно-телекоммуникационным сетям',
    info: 'Версия официального сайта для слабовидящих',
  },
  {
    num: '7',
    condition: 'Наличие специально оборудованных учебных кабинетов',
    info:
      'Имеется большой настенный экран для просмотра информации, а также кабинет оборудован микрофоном и колонками для усиления аудиовосприятия, необходимой компьютерной техникой',
  },
  {
    num: '8',
    condition: 'Наличие объектов для проведения практических занятий',
    info: 'Имеется',
  },
  {
    num: '9',
    condition:
      'Наличие библиотек, приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья',
    info:
      'Предоставление удаленного бесплатного доступа к электронной библиотеке ЧОУ ДПО «Профф»',
  },
  {
    num: '10',
    condition:
      'Наличие объектов спорта, приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья',
    info: 'Отсутствуют',
  },
  {
    num: '11',
    condition: 'Обеспечение специальных условий охраны здоровья',
    info: 'Санитарно-эпидемиологическое заключение от 05.04.2017 г.',
  },
];

const AreaTable = () => {
  const renderedPods = data.map(({ num, condition, info }) => {
    return (
      <StyledTr key={num}>
        <StyledTd className='text-lg font-bold tracking-wider'>{num}</StyledTd>
        <StyledTd>{condition}</StyledTd>
        <StyledTd>{info}</StyledTd>
        {/* <StyledTd>0</StyledTd>
        <StyledTd>0</StyledTd> */}
      </StyledTr>
    );
  });

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>№</StyledTh>
            {/* <StyledTh>КОЛИЧЕСТВО ВАКАНТНЫХ МЕСТ ДЛЯ ПРИЕМА(ПЕРЕВОДА)</StyledTh> */}
            <StyledTh>СПЕЦИАЛЬНОЕ УСЛОВИЕ</StyledTh>
            <StyledTh>ИНФОРМАЦИЯ О НАЛИЧИИ</StyledTh>
            {/* <StyledTh>ЗА СЧЁТ БЮДЖЕТНЫХ АССИГНОВАНИЙ МЕСТНЫХ БЮДЖЕТОВ</StyledTh>
            <StyledTh>
              ЗА СЧЁТ СРЕДСТВ ФИЗИЧЕСКИХ И (ИЛИ) ЮРИДИЧЕСКИХ ЛИЦ
            </StyledTh> */}
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedPods}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default AreaTable;
