import React from 'react';

import Layout from '../components/layout';
import AreaTable from '../components/areaTable';
import SEO from '../components/seo';

function Area() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Вакансии`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Образование'
      />

      <section className='flex flex-col items-center '>
        <h1 className=' text-center text-2xl uppercase text-green-800 font-bold mb-4'>
          ИНФОРМАЦИЯ ДЛЯ ЛЮДЕЙ С ОВЗ И ИНВАЛИДОВ
        </h1>
        <AreaTable />
        <p className='p-4 text-xl'>
          Приём обучающихся в ЧОУ ДПО «Профф» ведётся в соответствии с
          заключаемыми договорами об оказании платных образовательных услуг без
          ограничений. Квоты и контрольные цифры не установлены.
        </p>
      </section>
    </Layout>
  );
}

export default Area;
